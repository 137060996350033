.ant-menu-item-selected {
    background-color: #f0c9aa !important;
}

.ant-menu-submenu-selected{
    background-color: #ebd18e !important;
}

.ant-menu{
    background-color: #ebd18e !important;
}

.ant-menu-submenu-title{
    color: black !important;
}
